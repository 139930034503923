$primary-red: #e21a23;
$hover-primary-red: #b7151d;
$secondary-red: #961218;
$primary-blue: #06235b;
$hover-primary-blue: #04163a;
$dark-gray: #333333;
$primary-purple: #440b7f;
$hover-primary-purple: #32085e;
$medium-blue: #083377;
$light-blue: #d9e5fc;
$white: #fff;
$light-gray: #dedede;
$medium-gray: #716f6f;
$background-gray: #f8f8f8;
$background-blue-gray: #caced94d;
$background-light-gray: #f1f4f8cc;
$black: #000000;
$bgColor: #e5e5e5;
$light-light-gray: #cbcbcb;
$blue-gradient: linear-gradient(to right, #06235b, #0a3e8f);
$blue-select: #0e3a8f;
$black-outline: 2px solid #000;
$box-shadow-color: #3f434d14;

$emergency-red: #da1921;
$must-not-miss-orange: #a23e2e;

$neutral-light-04: #676d7a;
$neutral-light-05: #adadad;
$neutral-light-06: #caced9;
$neutral-light-07: #edf0f6;
$neutral-light-08: #f5f7fa;
$text-dark: #0e0909;

$dark-gray-01: #565b66;

// Padding
$padding-xxsmall: 0.0625rem; // 1px
$padding-xsmall: 0.125rem; // 2px
$padding-small: 0.25rem; // 4px
$padding-medium: 0.5rem; // 8px
$padding-large: 0.75rem; // 12px
$padding-xlarge: 1rem; // 16px
$padding-xxlarge: 1rem; // 16px

// Font Sizes
$font-size-xsmall: 0.625rem; // 10px
$font-size-small: 0.75rem; // 12px
$font-size-medium: 0.8125rem; // 13px
$font-size-large: 0.875rem; // 14px
$font-size-xlarge: 1rem; // 16px
$font-size-xxlarge: 1.125rem; // 18px
$font-size-xxxlarge: 1.5rem; // 24px

// Border Radius
$border-radius-small: 0.25rem; // 4px
$border-radius-medium: 0.3125rem; // 5px
$border-radius-large: 0.375rem; // 6px

// Gaps
$gap-xxsmall: 0.0625rem; // 1px
$gap-xsmall: 0.125rem; // 2px
$gap-small: 0.25rem; // 4px
$gap-medium: 0.5rem; // 8px
$gap-large: 0.625rem; // 10px
$gap-xlarge: 1rem; // 16px

// Max Height
$max-height: 25rem; // 400px

// Heights
$height-xxsmall: 0.5rem; // 8px
$height-xsmall: 0.75rem; // 12px
$height-small: 1rem; // 16px
$height-medium: 1.25rem; // 20px
$height-large: 2.5rem; // 40px
$padding-small: 0.25rem;
$padding-medium: 0.5rem;
$padding-large: 0.75rem;
$padding-xlarge: 1rem;
$padding-xxlarge: 2rem;

$font-size-xsmall: 0.625rem;
$font-size-small: 0.75rem;
$font-size-medium: 0.813rem;
$font-size-large: 0.875rem;
$font-size-xlarge: 1rem;
$font-size-xxlarge: 1.125rem;
$font-size-xxxlarge: 1.5rem;

$border-radius-xsmall: 0.125rem;
$border-radius-small: 0.25rem;
$border-radius-medium: 0.3125rem;
$border-radius-large: 0.375rem;

$gap-xsmall: 0.125rem;
$gap-small: 0.25rem;
$gap-medium: 0.5rem;
$gap-large: 0.625rem;

$height-xxsmall: 0.5rem;
$height-xsmall: 0.75rem;
$height-small: 1rem;
$height-medium: 1.25rem;
$height-large: 2.5rem;

$border-width: 1px;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semibold: 600;
$font-weight-bold: 700;

// Line Heights
$line-height-xxsmall: 1;
$line-height-tight: 1.1;
$line-height-normal: 1.2;
$line-height-loose: 1.5;

// Letter Spacing
$line-height-xxsmall: 0.875rem; // 14px
$line-height-xsmall: 1.125rem; // 18px
$line-height-small: 1.21875rem; // 19.5px
$line-height-medium: 1.3rem; // 20.8px
$line-height-large: 1.4rem; // 22.4px
$line-height-xlarge: 1.5rem; // 24px

$letter-spacing-normal: 0;
$letter-spacing-wide: 0.0175rem;

// box-shadow
$default-shadow: 0px 4px 8px 0px #3f434d0f;
$shadow-small: 0px 2px 4px 0px #49605b1f;
$shadow-medium: 0px 4px 11px 0px #49605b1f;
$shadow-large: 0px 8px 16px 0px #49605b1f;

// breakpoints
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1080px,
  xl: 1200px,
  xxl: 1400px,
);

@mixin respond-to($breakpoint) {
  @if map-has-key($grid-breakpoints, $breakpoint) {
    @media (max-width: map-get($grid-breakpoints, $breakpoint)) {
      @content;
    }
  }
}

$spacer: 1rem;

$focus-border: 1px solid $dark-gray;

$rem-base: 16px;