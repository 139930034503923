// 1. Include functions first (so you can manipulate colors, SVGs, calc, etc)
@import 'bootstrap/scss/functions';

// 2. Include any default variable overrides here
@import './variables';

// 3. Include remainder of required Bootstrap stylesheets
@import 'bootstrap/scss/variables';
$enable-dark-mode: false;

// 4. Include any default map overrides here

// 5. Include remainder of required parts
@import 'bootstrap/scss/maps';
@import 'bootstrap/scss/mixins';
@import 'bootstrap/scss/root';

// 6. Optionally include any other parts as needed
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/helpers';
@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/close';
@import 'bootstrap/scss/modal';
@import 'bootstrap/scss/utilities/api';

@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/accordion';
@import 'bootstrap/scss/nav';

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/Proxima_Nova_Regular.otf');
  font-weight: normal;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/Proxima_Nova_Thin.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/Proxima_Nova_Bold.otf');
  font-weight: 700;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../assets/fonts/Proxima_Nova_Extrabold.otf');
  font-weight: 800;
}

body {
  font-family: 'Proxima Nova', sans-serif;
}

html,
body {
  height: 100%;
  width: 100%;
  padding: 0;
  margin: 0;
}

div[role='tabpanel'] {
  height: 100%;
}

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1080px,
  xl: 1200px,
  xxl: 1400px,
);

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  white-space: nowrap;
}

.modal-header {
  margin: 0;
  width: 100%;
  color: $dark-gray;
  font-weight: $font-weight-bold;
  font-size: $font-size-xxxlarge;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}
